import React, {useState, useEffect, useRef} from "react";
import "@src/scss/App.scss";
import Seo from "@components/seo";
import Layout from "@components/layout";
import { graphql } from 'gatsby';
import gql from "graphql-tag"
import { useQuery } from '@apollo/client';
import ModuleSection from '@components/modules/moduleWrapper';
import BannerRender from "@components/modules/Banner/BannerRender";
import NewsCard from '@components/modules/AllNews/newsCard';
import Col from "react-bootstrap/Col";
import Stack from "react-bootstrap/Stack";
import Button from "react-bootstrap/Button";
import ButtonLink from '@components/ButtonLink';
import BookValuation from '@components/modules/BookValuation';
import { Routes } from '@components/routes';
import Loader from '@components/loader';
import { BreakpointProvider } from '@components/breakpoint';
import { useBreakpoint } from '@components/breakpoint.js';
 
import { contentItem } from '@components/utils/animations';
// import BlogDetails from "../../templates/blog-details";
import SubscribeNewsLetter from '@components/modules/Forms/SubscribeNewsLetter';

const GET_ALL_NEWS = gql`
    query GetNews {
        allNews(publicationState: LIVE, sort: "Date:DESC", where: {ShowOnNewsLetterPage : true}) {
            id
            Slug
            Date
            tag
            Categories {
                Name
              }
            Attachment {
                url
                id
            }
            Issue
            ShowOnNewsLetterPage
            imagetransforms
            BannerImage {
                ... on ComponentModulesBannerImage {
                    id
                    VideoUrl
                    ItemText
                    ItemTitle
                    id
                    Image {
                        alternativeText
                        url
                    }
                }
            }
        }
    }
`;

const sliderConfig = {
    slidesPerView: "auto",
    spaceBetween: 32,
    pagination: false,
    breakpoints: {
        1200: {
            slidesPerView: "auto",
            spaceBetween: 40
        }
    }
}

const queries = {
    md: '(min-width: 992px)',
    // or: '(orientation: portrait)', // we can check orientation also
}

const AfterTitle = ({ categories, filter, clickFilter, buttonCTA  }) => {
    // const tags_refetched = [...new Set(news?.map(item => item.tag))];
    // const tags = ["","Renting","Buying", "Selling", "General"];
    return(
        <>
            {categories?.length > 0 &&
                <div variants={contentItem} className="mt-32 mt-md-40 mt-lg-56 banner-links-section">
                    {/* <Carousel className="image-banner-btn__carousel" {...sliderConfig}>
                    <SwiperSlide>
                                        <Button onClick={() => clickFilter('All')} variant={filter === "All" ? "link-secondary-underlined" : "link-secondary"}>All Categories</Button>
                                    </SwiperSlide>
                                    {categories.map((item, i) => { 
                                        return(
                                    <SwiperSlide key={i}>
                                        <Button onClick={() => clickFilter(item.Name)} variant={filter === item.Name ? "link-secondary-underlined" : "link-secondary"}>{item.Name}</Button>
                                    </SwiperSlide>
                                )
                           
                        })} 
                    </Carousel> */}
                    <ButtonLink manualLink={`#subscribeNewsletterForm`} icon="emailIcon" label={`Subscribe to Newsletter`}  variant="secondary" btnClass="text-nowrap" />                                          
                    
                </div>
            }
        </>
    )
}



const VideoLandingPages = (props) => {
    const myRef = React.createRef();
    const { loading, error, data } = useQuery(GET_ALL_NEWS);
    const [filter, setFilter] = useState("All")
    const clickFilter = (e) => {
        setFilter(e)
        var arr = [];
        data?.allNews?.filter(list => list?.Categories?.map(c => c.Name).includes(e)).map((item, index) => {
            arr.push(item)
        })
        setBlogs(e === "All" ? data?.allNews : arr)
        myRef?.current?.scrollIntoView({ behavior: 'smooth' })
    }
    const [blogs, setBlogs] = useState([])
    const breakpoints = useBreakpoint();
    const postsPerPage = 12;
    let arrayForHoldingPosts = [];
    // const [] = useState(0);
    const [postsToShow, setPostsToShow] = useState([]);
    const [next, setNext] = useState(12);
    const { glstrapi: { pages, siteConfig } } = props?.data;
    const loopWithSlice = (start, end) => {
        const slicedPosts = blogs?.slice(0, end);
        if (blogs) {
            arrayForHoldingPosts = [...arrayForHoldingPosts, ...slicedPosts];
            setPostsToShow(arrayForHoldingPosts);
        }

    };

    const handleShowMorePosts = () => {
        loopWithSlice(next, next + postsPerPage);
        setNext(next + postsPerPage);
    };

    useEffect(() => {
        loopWithSlice(0, postsPerPage);
        setNext(12)
    }, [blogs?.length]);
    useEffect(() => {
        setBlogs(data?.allNews)
    }, [data]);
    let myblogslug = ''
    let pathUri_main = props.location.pathname.split("/media/uae-property-news-letter/");

    if (pathUri_main[1]) {
        let pathUri_arr = pathUri_main[1].split("?")[0].split("/")
        myblogslug = pathUri_arr[0];
    }

    // if (myblogslug) {
    //     return (
    //         <BlogDetails myslug={myblogslug} isNewsPage={true} />
    //     )

    // }
    else {

       
        var page = pages[0]
        // const pageurl = typeof window !== 'undefined' ? window.location.pathname : page?.Slug
        const breadcrumbs = [
            { label: "Media", url: `${Routes.MediaPage}` },
            { label: page?.Pagename, url: `${Routes.MediaPage}/${page?.Slug}` }
        ]

        return (
            <Layout popularSearch={"Media_Layout"} bodyClass="blog-landing-wrapper news-landing">
                <Seo
                    title={page?.Metadata?.title ? page.Metadata.title : page?.Title}
                    description={page?.Metadata?.description ? page.Metadata.description : null}
                    // image={page?.Metadata?.image?.url ? page.Metadata.image.url : null}
                    image={page?.Metadata?.image?.url_sharp?.childImageSharp ? page.Metadata.image.url_sharp.childImageSharp.resize : null}
                />
                <BannerRender
                    page={page}
                    breadcrumbs={breadcrumbs}
                    afterTitle={<AfterTitle
                        categories={props?.data?.glstrapi?.blogCategories}
                        clickFilter={clickFilter} filter={filter} buttonCTA={page?.BannerImage[0]?.BannerCtaBtn} />}
                />
                <div ref={myRef} className={"scrolling-pad"} />
                <ModuleSection sectionSpacing="py-40 py-md-64 py-lg-80">
                    <Stack className="gap-20 gap-md-40 flex-md-row mb-40">
                        <div>Showing {blogs?.length} {blogs?.length > 1 ? 'issues' : 'issue'}</div>
                        {/* <a href="https://www.youtube.com/c/Exclusive-links" target="_blank" rel="noreferrer" className="d-block ms-md-auto">
                        <StarberryIcons iconName="emailIcon" className="bi" />
                        <span>Subscribe to our Newsletter</span>
                    </a> */}
                    </Stack>
                    <Loader loading={loading} error={error}>
                        <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3 gy-24 gx-md-16 gy-md-40 g-lg-40">
                            {postsToShow?.map((news, i) => {
                                if (breakpoints.md ? i === 2 : i === 1) {
                                    return (
                                        <React.Fragment key={i}>
                                            <Col>
                                                <NewsCard news={news} custom={i} />
                                            </Col>
                                            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                                <BookValuation
                                                    moduleData={{
                                                        Title: "Find a <strong>Dubai property expert to</strong> discuss your requirements",
                                                        Text: "Established since 2005 we have a team of qualified and experienced Client Managers and Community Specialists ready. to help."
                                                    }}
                                                    Buttons={[
                                                        {
                                                            id: "1",
                                                            btnClass: "btn-secondary",
                                                            label: "Subscribe to Newsletter",
                                                            icon: "emailIcon",
                                                            manualLink: `#subscribeNewsletterForm`
                                                        }
                                                    ]}
                                                />
                                            </Col>
                                        </React.Fragment>
                                    )
                                } else {
                                    return (
                                        <Col key={i}>
                                            <NewsCard news={news} custom={i} />
                                        </Col>
                                    )
                                }
                            })}
                        </div>
                        {postsToShow?.length !== blogs?.length &&
                            <Col xs={12} md={12} xxl={12} className="mt-16 mt-lg-40">
                                <Button
                                    variant="loadMore"
                                    // disabled={pageDetails && start + limit >= pageDetails?.totalCount}
                                    // onClick={() => refetch({ limit: setNext(next + postsPerPage) })}
                                    onClick={handleShowMorePosts}
                                    className="w-100"
                                >
                                    Load more
                                </Button>
                            </Col>
                        }
                    </Loader>
                </ModuleSection>
                <SubscribeNewsLetter
                    sectionClass="section-contact-form"
                    title="Subscribe to Newsletter" 
                />
            </Layout>
        )
    }

}

const VideoLandingPagesWrapper = (props) => (
    <BreakpointProvider queries={queries}>
        <VideoLandingPages {...props} />
    </BreakpointProvider>
)
export default VideoLandingPagesWrapper

export const query = graphql`
query {
    glstrapi { 
        siteConfig {
            id
            News_Letter_Attachment {
              url
            }
        }
        blogCategories(publicationState: LIVE) {
            Name
            Publish
        }
        pages(publicationState: LIVE, where: {PageTemplate: "NewsLetterLanding"}) {
            Pagename
            Title
            Slug
            HeaderTemplate
            imagetransforms
            BannerImage {
                ... on GLSTRAPI_ComponentModulesBannerImage {
                    ...ImgBtnFragment
                }
                ... on GLSTRAPI_ComponentModulesLatestPodcasts {
                    ...LatestPodcastsFragment
                }
            }
            Metadata {
                title
                description
                image {
                    url
                    url_sharp {
                        childImageSharp {
                            resize(width: 1200) {
                                src
                                height
                                width
                            }
                        }
                    }
                }
            }
        }
    }
}
`