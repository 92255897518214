import * as React from "react";
import { graphql } from "gatsby";
import ModuleTitle from '@components/modules/Title';
import ModuleText from '@components/modules/Text';
import { Stack } from "react-bootstrap";
import ButtonLink from '@components/ButtonLink';
import { StaticImage } from "gatsby-plugin-image";
import { useLocation } from "@reach/router"
import Routes from '@components/routes';

import { fadeInFromTop } from '@components/utils/animations';

import './bookValuation.scss';

const defaultData = {
    moduleData: {
        Title: "<p>Find out what your property is worth with a <strong>free valuation</strong></p>",
        Text: "<p>We can provide a comparative market analysis report including historic sales data & price points.</p>"
    },
    Buttons: [
        {
            id: "1",
            btnClass: "btn-secondary",
            label: "Book a Valuation",
            icon: "homeIcon",
            manualLink: `${Routes.Sell}${Routes.RequestValuation}`,
            func: "BookValuationModal"
        }
    ]
}

const saleResultData = {
    moduleData: {
        Title: "<p>Find out what your property is worth with a <strong>free valuation</strong></p>",
        Text: "<p>We can provide a comparative market analysis report including historic sales data & price points.</p>"
    },
    Buttons: [
        {
            id: "1",
            btnClass: "btn-secondary",
            label: "Book a Valuation",
            icon: "homeIcon",
            manualLink: `${Routes.Sell}${Routes.RequestValuation}`,
            func: "BookValuationModal"
        }
    ]
}

const rentResultData = {
    moduleData: {
        Title: "<p>Have you considered <strong>buying property in Dubai</strong>?</p>",
        Text: "<p>We have options available off plan and on the secondary market that could suit your requirements.</p>"
    },
    Buttons: [
        {
            id: "1",
            btnClass: "btn-secondary",
            label: "Request a call back",
            icon: "homeIcon",
            manualLink: `${Routes.ContactUsPage}`,
            func: "ContactUsModal"
        }
    ]
}

const offplanSaleResultData = {
    moduleData: {
        Title: "<p>Do you want to know more about our <strong>post handover services</strong>?</p>",
        Text: "<p>Exclusive Links can help you with long term leasing, property management, short term rentals and resales - all supported with years of knowledge and experience.</p>"
    },
    Buttons: [
        {
            id: "1",
            btnClass: "btn-secondary",
            label: "Speak to a member of our team",
            icon: "homeIcon",
            manualLink: `${Routes.ContactUsPage}`,
            func: "ContactUsModal"
        }
    ]
}
const shorttermRentResultData = {
    moduleData: {
        Title: "<p>Do you own property in Dubai and are <strong>considering short term</strong> rentals?</p>",
        Text: "<p>Exclusive Links Vacation Homes are setting the new standard in short term rentals in Dubai.</p>"
    },
    Buttons: [
        {
            id: "1",
            btnClass: "btn-secondary",
            label: "Request a Projection",
            icon: "homeIcon",
            manualLink: `${Routes.RequestProjection}`,
            func: "BookValuationModal"
        }
    ]
}

const areadefaultData = {
    moduleData: {
        Title: "<p>Looking to <strong>buy or rent</strong> in Dubai?</p>",
        Text: "<p>Our team of Client Managers will introduce you to various property options across Dubai.</p>"
    },
    Buttons: [
        {
            id: "1",
            btnClass: "btn-secondary",
            label: "Speak to a Client Manager",
            icon: "homeIcon",
            manualLink: `${Routes.Sell}${Routes.RequestValuation}`,
            func: "ContactUsModal"
        }
    ]
}

const projectsDefaultData = {
    moduleData: {
        Title: "<p>Do you want to know more about <strong>Dubai Real Estate</strong>?</p>",
        Text: "<p>Exclusive Links offers the complete property experience in Sales, Leasing and Property Management.</p>"
    },
    Buttons: [
        {
            id: "1",
            btnClass: "btn-secondary",
            label: "Contact Us",
            icon: "homeIcon",
            manualLink: `${Routes.Sell}${Routes.RequestValuation}`,
            func: "ContactUsModal"
        }
    ]
}

const blogDefaultData = {
    moduleData: {
        Title: "<p>Are you looking for <strong>a name you can trust?</strong></p>",
        Text: "<p>We harness professional and market expertise from all areas of the business and work with a transparent client centric approach.</p>"
    },
    Buttons: [
        {
            id: "1",
            btnClass: "btn-secondary",
            label: "Contact Us",
            icon: "homeIcon",
            manualLink: `${Routes.Sell}${Routes.RequestValuation}`,
            func: "ContactUsModal"
        }
    ]
}

const BookValuation = ({ moduleData, Buttons, enableAreaGuide }) => {

    var {pathname} = useLocation()

    var splitValue = pathname.split("/")

    var selectData = moduleData?.Title ? moduleData : defaultData.moduleData;
    var selectBtnData = Buttons?.length > 0 ? Buttons : defaultData.Buttons;

    if(splitValue?.length === 5 && pathname.includes("uae-area-guides") && !moduleData?.Title){
        selectData = areadefaultData.moduleData ;
        selectBtnData = areadefaultData.Buttons;
    }
    if (splitValue?.length === 6 && pathname.includes("uae-area-guides") && !moduleData?.Title){
        selectData = projectsDefaultData.moduleData ;
        selectBtnData = projectsDefaultData.Buttons;
    }
    if(splitValue?.length === 5 && pathname.includes("uae-property-blog") && !moduleData?.Title){
        selectData = blogDefaultData.moduleData ;
        selectBtnData = blogDefaultData.Buttons;
    }

    if(pathname.includes("/properties/for-sale/")) {
        selectData = saleResultData.moduleData;
        selectBtnData = saleResultData.Buttons;
    }
    if(pathname.includes("/properties/for-rent/")) {
        selectData = rentResultData.moduleData;
        selectBtnData = rentResultData.Buttons;
    }
    if(pathname.includes("/off-plan-properties/for-sale/")) {
        selectData = offplanSaleResultData.moduleData;
        selectBtnData = offplanSaleResultData.Buttons;
    }
    if(pathname.includes("/properties/short-term-for-rent/")) {
        selectData = shorttermRentResultData.moduleData;
        selectBtnData = shorttermRentResultData.Buttons;
    }
    return(
        <div
            className={`p-32 p-md-48 p-lg-48 book-valuation ${moduleData?.moduleClass ? moduleData.moduleClass : ""}`}
        >
            <StaticImage
                src="../../../images/pattern.jpg"
                quality={50}
                formats={["auto", "webp"]}
                alt="Background Image - Exclusive Links"
                className="pattern-img"
            />
            <div className="book-valuation__wrapper" variants={fadeInFromTop}>
                <Stack className="gap-0 gap-lg-16 flex-lg-row">
                    <div>
                        {selectData?.Title &&
                            <ModuleTitle
                                title={selectData.Title}
                                className="book-valuation__title mb-24 mb-md-8"
                            />
                        }
                        {selectData?.Text &&
                            <ModuleText
                                text={selectData.Text}
                                className="book-valuation__text d-none d-md-block mb-md-32 mb-lg-0"
                            />
                        }
                    </div>
                    {selectBtnData.length > 0 &&
                        <Stack className="gap-16 flex-md-row gap-md-24 align-items-lg-center justify-content-lg-end flex-shrink-0">
                            {selectBtnData.map((btn) => {
                                return(
                                    <ButtonLink key={btn?.id} {...btn} variant="secondary" className="book-valuation__btn" />
                                )
                            })}
                        </Stack>
                    }
                </Stack>
            </div>
        </div>
    )
}

export default BookValuation

export const bookValuationFragment = graphql`
    fragment BookValuationFragment on GLSTRAPI_ComponentModulesBookValuation {
        id
        moduleType
        moduleData {
            Text
            Title
            moduleClass
            sectionSpacing
        }
        Buttons {
            id
            manualLink
            btnClass
            label
            icon
            func
            page {
                Slug
                id
                Parent {
                    Slug
                }
                Parent2 {
                    Slug
                }
                Parent3 {
                    Slug
                }
            }
        }
    }
`